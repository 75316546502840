import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { TransitionGroup, CSSTransition } from "react-transition-group"

import { roles } from "config/roles"

import DefaultContainer from "containers/Default"
import LivenessContainer from "containers/Liveness"

import Home from "pages/Home"
import Confirmation from "pages/Confirmation"
import Address from "pages/Address"
import DocFront from "pages/DocFront"
import DocBack from "pages/DocBack"
import Proof from "pages/Proof"
import Success from "pages/Success"
import GetLiveness from "pages/GetLiveness"
import Liveness from "pages/Liveness"
import RetryLiveness from "pages/RetryLiveness"
import Quiz from "pages/Quiz"
import RetryQuiz from "pages/RetryQuiz"
import End from "pages/End"
import Error404 from "pages/404"

import AuthRoute from "./AuthRoute"

export default () => {
  return (
    <Router>
      <Route
        render={() => (
          <div className="content">
            <TransitionGroup component="div">
              <CSSTransition timeout={250} classNames="fade">
                <Switch>
                  <DefaultContainer  path="/home" component={Home} />
                  <AuthRoute path="/confirmation" parent={DefaultContainer} child={Confirmation} roles={[roles.CAN_INSERT_SMS_TOKEN]} />
                  <AuthRoute path="/address" parent={DefaultContainer} child={Address} roles={[roles.CAN_CONSULT_CEP, roles.CAN_SEND_ADDRESS]} />
                  <AuthRoute path="/docfront" parent={DefaultContainer} child={DocFront} roles={[roles.CAN_SEND_DOC_FRONT]} />
                  <AuthRoute path="/docback" parent={DefaultContainer} child={DocBack} roles={[roles.CAN_SEND_DOC_BACK]} />
                  <AuthRoute path="/proof" parent={DefaultContainer} child={Proof} roles={[roles.CAN_SEND_ADDRESS_DOC]} />
                  <AuthRoute path="/success" parent={DefaultContainer} child={Success} roles={[roles.CAN_COMPLETE]} />
                  <AuthRoute path="/getLiveness" parent={DefaultContainer} child={GetLiveness} roles={[roles.CAN_SEND_FIRST_LIVENESS_FRAME, roles.CAN_RETRY_LIVENESS]} />
                  <AuthRoute path="/liveness" parent={LivenessContainer} child={Liveness} roles={[roles.CAN_SEND_LIVENESS]} />
                  <AuthRoute path="/retryLiveness" parent={LivenessContainer} child={RetryLiveness} roles={[roles.CAN_RETRY_LIVENESS]} />
                  <AuthRoute path="/quiz" parent={DefaultContainer} child={Quiz} roles={[roles.CAN_GET_QUIZ, roles.CAN_ANSWER_QUIZ]} />
                  <AuthRoute path="/retryQuiz" parent={DefaultContainer} child={RetryQuiz} roles={[roles.CAN_RETRY_QUIZ]} />
                  <AuthRoute path="/end" parent={DefaultContainer} component={End} />

                  <DefaultContainer component={Error404} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </div>
        )}
      />
    </Router>
  )
}
