import React, { useState, useRef, useEffect, useCallback, useContext } from "react"
import Webcam from "react-webcam"
import CircularProgress from "@material-ui/core/CircularProgress"
import jwtDecode from "jwt-decode"

import Container from "./styles"
import { getFullFaceDescription } from "api/face"
import { image64toCanvasRef } from "utils/imageFns"
import { Store } from "store"
import { postFile } from "api/api"
import { verifyTokenRole } from "config/tokenRouter"
import { SET_TOKEN } from "store/reducers/tokenReducer"
import { roles } from "config/roles"

const messagesArray = [
  {
    message: "",
    delay: 1
  },
  {
    message: "Sorria por 3 segundos AGORA",
    delay: 3
  },
  {
    message: "Feche um dos olhos por 3 segundos AGORA",
    delay: 3
  }
]
const framesArray = [1, 3, 3]
const totalFrames = messagesArray.length

export default function({ history }) {
  const cameraRef = useRef()
  const canvasRef = useRef(null)
  const [detectMessage, setDetectMessage] = useState("Iniciando detecção facial")
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [frameIndex, setFrameIndex] = useState(0)
  const [state, dispatch] = useContext(Store)
  const { token } = state
  const [cameraStarted, setCameraStarted] = useState(false)
  const [validFace, setValidFace] = useState(false)

  const getFrame = useCallback(
    async (screenshot, faceBox) => {
      const canvas = canvasRef.current
      let formData = new FormData()
      let file = null

      await image64toCanvasRef(canvasRef.current, screenshot, faceBox)
      const croppedImage = canvas.toDataURL("image/jpeg", 0.9)

      // if (frameIndex + 1 === 2) {
      //   let pictureFormData = new FormData()
      //   let picture = null

      //   await fetch(screenshot)
      //     .then(res => res.blob())
      //     .then(blob => {
      //       picture = new File([blob], `liveness_${frameIndex}.jpeg`, { type: "image/jpeg" })
      //       pictureFormData.append("file", picture)
      //     })

      //   postFile({
      //     url: "/liveness",
      //     token: token.value,
      //     data: pictureFormData,
      //     history
      //   })
      // }

      await fetch(croppedImage)
        .then(res => res.blob())
        .then(blob => {
          file = new File([blob], `liveness_${frameIndex}.jpeg`, { type: "image/jpeg" })
          formData.append("file", file)
        })

        const percentage = ((frameIndex + 1) * 100) / totalFrames
        setProgress(percentage)
  
        if (frameIndex + 1 === totalFrames) {
          setDetectMessage("")
          setLoading(true)
        }
  
        const res = await postFile({
          url: "/liveness",
          token: token.value,
          data: formData,
          history
        })
  
        if (res) {
          const { identity } = jwtDecode(res.token)

          if (frameIndex + 1 === totalFrames  || identity.role !== roles.CAN_SEND_LIVENESS) {
            dispatch({ type: SET_TOKEN, payload: res.token })
            return verifyTokenRole(res.token, history)
          } else {
            setFrameIndex(frameIndex + 1)
          }
        }
    },
    [dispatch, frameIndex, history, token.value]
  )

  const findFace = useCallback(async () => {
    return new Promise(async resolve => {
      if (cameraRef.current && cameraRef.current.getScreenshot()) {
        const screenshot = cameraRef.current.getScreenshot()
        await getFullFaceDescription(screenshot).then(async fullDesc => {
          if (fullDesc) {
            setValidFace(true)
            getFrame(screenshot, fullDesc._box)
            resolve(true)
          }
          resolve(false)
        })
      } else {
        setValidFace(false)
        resolve(false)
      }
    })
  }, [getFrame])

  const main = useCallback(async () => {
    if (frameIndex <= totalFrames) {
      if (cameraRef.current) {
        if (cameraRef.current && cameraRef.current.getScreenshot()) {
          await getFullFaceDescription(cameraRef.current.getScreenshot()).then(async fullDesc => {
            if (fullDesc) {
              const face_box = fullDesc._box

              if (face_box._width < 430) {
                setValidFace(false)
                  setDetectMessage("Aproxime seu rosto")
                  setTimeout(main, 300)
              } else if (face_box._width > 530) {
                setValidFace(false)
                setDetectMessage("Afaste seu rosto")
                setTimeout(main, 300)
              } else {
                setDetectMessage("")
                setValidFace(true)

                if (frameIndex < totalFrames) {
                  const currentMessage = messagesArray[frameIndex]
                  const currentFrameDelay = framesArray[frameIndex]

                  setDetectMessage(currentMessage.message)

                  setTimeout(async () => {
                    let descFound = false

                    while (!descFound) {
                      descFound = await findFace()
                    }
                  }, currentFrameDelay * 1000)
                }
              }
            } else {
              setDetectMessage("Aguarde, detectando seu rosto...")
              setTimeout(main, 300)
            }
          })
        } else {
          setDetectMessage("Aguarde, detectando seu rosto...")
          setTimeout(main, 300)
        }
      }
    }
  }, [findFace, frameIndex])

  useEffect(() => {
    main()
  }, [main])

  const handleCameraStart = () => {
    setCameraStarted(true)
  }

  return (
    <Container>
      
        <div className="cameraContainer">
          {cameraStarted && (
            <React.Fragment>
            <div className={`cameraOverlay ${!detectMessage && "off"}`}>
              <span>{detectMessage}</span>
            </div>
            <div className={`loadingOverlay ${loading && "on"}`}>
              <CircularProgress className="loadingLoader" />
            </div>
            <div className={`mask ${validFace ? 'valid' : ''}`} />
            <div className="progress" style={{ width: `${progress}%` }} />
            </React.Fragment>
          )}
          <Webcam audio={false} ref={cameraRef} minScreenshotHeight={700} screenshotFormat="image/jpeg" videoConstraints={{ facingMode: "user" }} onUserMedia={handleCameraStart} />
          <canvas ref={canvasRef} id="canvas"></canvas>
        </div>
      
    </Container>
  )
}
