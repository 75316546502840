import styled from "styled-components"

export default styled.button`
  background-color: ${props => props.theme.palette.secondary};
  color: ${props => props.theme.palette.secondaryContrast};
  border: 0;
  border-radius: ${props => `${props.theme.radius}rem`};
  width: 100%;
  max-width: 60rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  height: 5rem;
  outline: none;
  margin-top: 3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  transition: all 0.3s ease;
  letter-spacing: 1.71px;

  &:focus {
    outline: none;
  }

  &.disabled {
    background-color: ${props => props.theme.palette.gray[300]};
    width: 5rem;
    border-radius: 3rem;
  }
`
