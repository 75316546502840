import React from "react"

import Container from "./styles"

export default function() {
  return (
      <Container>
        <h1>Acesso não autorizado!</h1>
      </Container>
  )
}
