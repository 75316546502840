import React, { useRef, useState, useContext, useEffect } from "react"

import Container from "./styles"
import { Store } from "store"
import { Button } from "components/Button"
import { post } from "api/api"
import { SET_TOKEN } from "store/reducers/tokenReducer"
import { verifyTokenRole } from "config/tokenRouter"

export default props => {
  const [{ token }, dispatch] = useContext(Store)

  const firstRef = useRef()
  const secondRef = useRef()
  const thirdRef = useRef()
  const fourthRef = useRef()

  const refsArray = [firstRef, secondRef, thirdRef, fourthRef]
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    firstRef.current.focus()
  }, [])

  const handleSubmit = async () => {
    setError("")
    const code = refsArray.reduce((acc, value) => acc + "" + value.current.value, "")

    if (code.length < 4) {
      return setError("Preencha o código corretamente")
    }

    setLoading(true)
    const res = await post({
      url: "/sms_validate",
      token: token.value,
      data: { sms: code }
    })

    if (res) {
      dispatch({ type: SET_TOKEN, payload: res.token })
      return verifyTokenRole(res.token, props.history)
    }
    setLoading(false)
  }

  const autoTab = index => {
    if (refsArray[index].current.value) {
      if (index < refsArray.length - 1) {
        refsArray[index + 1].current.focus()
      }
    } else {
      if (index > 0) {
        refsArray[index - 1].current.focus()
      }
    }
  }

  return (
    <React.Fragment>
      <h1>Confirme seu celular</h1>
      <h4>Insira o código enviado para você</h4>

      <Container>
        <div className="codeContainer">
          <input type="tel" readOnly value="I" />
          <input type="tel" readOnly value="-" />
          {refsArray.map((item, i) => (
            <input key={i} type="tel" ref={item} maxLength="1" onKeyUp={() => autoTab(i)} />
          ))}
        </div>
        {error && <div className="error">{error}</div>}

        <small className="hint">O recebimento do SMS pode levar até 5 minutos</small>

        <Button onClick={handleSubmit} loading={loading}>
          Confirmar
        </Button>
      </Container>
    </React.Fragment>
  )
}
