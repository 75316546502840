import React, { useContext } from "react"
import { Redirect } from "react-router-dom"
import { toast } from "react-toastify"
import jwtDecode from "jwt-decode"

import { Store } from "store"

export default ({ parent: Parent, child, roles, location, ...rest }) => {
  const [{ token }] = useContext(Store)

  if (roles) {
    if (!token.value) {
      toast.error("Token de acesso inválido!")
      return <Redirect to="/" />
    }

    const { identity } = jwtDecode(token.value)
    
    return roles.includes(identity.role) ? <Parent component={child} {...rest} /> : <Redirect to="/" />
  }

  return <Parent component={child} {...rest} />
}
